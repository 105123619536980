import { NewsLetterSub } from "../../entity/NewsLetterSub"

export default class NewsletterSubAPIRepository {
  public static async sub(sub: NewsLetterSub) {
    const runtime = useRuntimeConfig()
    const url = `${runtime.public.apiBaseURL}/api/v1/newsletter`

    const dto = NewsLetterSub.to(sub)
    const respone = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dto)
    })

    if (!respone.ok) {
      throw new Error('Cannot subscribe to newsletter')
    }
  }
}

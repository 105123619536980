<template>
  <div class="newsletter-sub">
    <div class="newsletter-sub__image" />
    <div class="newsletter-sub__form">
      <form>
        <div class="newsletter-sub__form__title">
          <DynLang
            by-key="newsletter-posible-discount"
            capitalize-first
          />
        </div>
        <div class="newsletter-sub__form__label">
          <DynLang
            by-key="newsletter-sub-pls"
            capitalize-first
          />*
        </div>

        <div class="newsletter-sub__form__input-wrapper">
          <div class="newsletter-sub__form__input-wrapper__input">
            <SplInputBox
              id="newsletter-name"
              v-model="sub.name"
              label-translation-key="name"
            />
          </div>
          <div
            v-if="!isNameValid"
            class="newsletter-sub__form__error"
          >
            *<DynLang
              by-key="buyer-form-name-error"
              capitalize-first
            />
          </div>

          <div class="newsletter-sub__form__input-wrapper__input">
            <SplInputBox
              id="newsletter-email"
              v-model="sub.email"
              label-translation-key="email"
            />
          </div>
          <div
            v-if="!isEmailValid"
            class="newsletter-sub__form__error"
          >
            *<DynLang
              by-key="buyer-form-email-error"
              capitalize-first
            />
          </div>

          <div class="newsletter-sub__form__location-seletor">
            <SelectBox
              id="get-away-newsletter"
              variant="border"
              :data="countries"
              display-prop="label"
              @input="selectCountry"
            >
              <template #label>
                <span v-if="selectedCountryLabel">
                  {{ selectedCountryLabel }}
                </span>
                <DynLang
                  v-else
                  by-key="newsletter-country-selector"
                  capitalize-first
                />
              </template>
            </SelectBox>

            <SelectBox
              v-if="!!provincesView.length"
              id="get-away-newsletter"
              variant="border"
              :data="provincesView"
              display-prop="label"
              @input="selectProvince"
            >
              <template #label>
                <span v-if="selectedProvinceLabel">
                  {{ selectedProvinceLabel }}
                </span>
                <DynLang
                  v-else
                  by-key="region"
                  capitalize-first
                />
              </template>
            </SelectBox>
          </div>

          <div
            v-if="!isLocationValid"
            class="newsletter-sub__form__error"
          >
            *<DynLang
              by-key="newsletter-form-location-error"
              capitalize-first
            />
          </div>
        </div>

        <div class="newsletter-sub__form__input-wrapper">
          <SplCheckbox
            id="newsletter-conditions-1"
            v-model="termsAndConditions"
          >
            <label
              class="gfield_consent_label"
              for="newsletter-conditions-1"
            >
              Acepto el <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/aviso-legal/"
                no-lang
                is-target-blank
              >
                Aviso Legal
              </SplNuxtLink>,
              la <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/politica-de-privacidad/"
                no-lang
                is-target-blank
              >Política de
                Privacidad</SplNuxtLink>
              y la <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/politica-de-cookies/"
                no-lang
                is-target-blank
              >Política de Cookies
              </SplNuxtLink> </label>
          </SplCheckbox>

          <div
            v-if="!isTermAndConditionsValid"
            class="newsletter-sub__form__error"
          >
            *<DynLang
              by-key="buyer-form-terms-error"
              capitalize-first
            />
          </div>

          <!-- <SplCheckbox id="newsletter-conditions-2">
            <label for="newsletter-conditions-2">Acepto recibir comunicaciones comerciales</label>
          </SplCheckbox> -->
        </div>

        <div class="newsletter-sub__form__button">
          <SplButton
            variant="primary"
            size="md"
            @click="sendNewsletterSub()"
          >
            <DynLang
              by-key="newsletter-btn"
              capitalize-all
            />
          </SplButton>
        </div>

        <div class="newsletter-sub__form__label">
          *<DynLang
            by-key="newsletter-sub-notice"
            capitalize-first
          />
        </div>
      </form>
    </div>

    <Separator hide />
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import SplButton from '../shared/form/SplButton.vue'
import SplInputBox from '../shared/form/SplInputBox.vue'
import SplCheckbox from '../shared/form/SplCheckbox.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import Separator from '~/core/components/shared/Separator.vue'

import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

import type { Location } from '~/core/ts/entity/Location'
import { NewsLetterSub } from '~/core/ts/entity/NewsLetterSub'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import NewsletterSubAPIRepository from '~/core/ts/repository/newsletter/NewsletterSubAPIRepository'
import LoadingEventBus from '~/core/events/loading'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SplButton,
    SplInputBox,
    SplCheckbox,
    SelectBox,
    Separator,
  },
  async setup() {
    const {currentLang} = useLangSelector()

    onMounted(() => {
      sub.value.langCode = currentLang
    })

    const sessionLocation = useSessionLocation()

    const [
      countries,
      provinces,
    ] = await Promise.all([
      (async () => {
        return await useFetchProxy<Location[]>(
          '/api/locations/locations-by-type',
          {
            method: 'post',
            body: {
              type: 'country'
            },
            key: 'newsletter-sub-locations-by-type-country'
          },
        )
      })(),
      (async () => {
        return await useFetchProxy<Location[]>(
          '/api/locations/locations-by-type',
          {
            method: 'post',
            body: {
              type: 'region'
            },
            key: 'newsletter-sub-locations-by-type-region'
          },
        )
      })(),
    ])

    const sub = ref<NewsLetterSub>(NewsLetterSub.createEmpty('es'))

    const termsAndConditionsFirstTime = ref(true)
    const termsAndConditions = ref(false)

    const selectedProvince = ref<Location | null>(null)
    function selectProvince(province: Location) {
      selectedProvince.value = province

      sub.value.politicalLocationUUID = province.uuid
    }

    function selectCountry(country: Location) {
      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (selectedCountry && selectedCountry.countryCode !== country.countryCode) {
        selectedProvince.value = null
        sub.value.politicalLocationUUID = ''
      }

      sessionLocation.storeCountryInSession(country)
    }

    const selectedProvinceLabel = computed(() => {
      if (!selectedProvince.value) {
        return ''
      }

      return selectedProvince.value.label
    })

    const selectedCountryLabel = computed(() => {
      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (selectedCountry) {
        return selectedCountry.label
      }

      return ''
    })

    const provincesView = computed(() => {
      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (!selectedCountry) {
        return []
      }

      return provinces.filter((location) => {
        return location.countryCode === selectedCountry.countryCode
      })
    })

    const isNameValid = computed(() => NewsLetterSub.isNameValidOrFresh(sub.value))
    const isEmailValid = computed(() => NewsLetterSub.isEmailValidOrFresh(sub.value))
    const isLocationValid = computed(() => NewsLetterSub.isLocationValidOrFresh(sub.value))
    const isTermAndConditionsValid  = computed(() => termsAndConditionsFirstTime.value || termsAndConditions.value)

    const isFormValid = computed(() => {
      if (!termsAndConditions.value) {
        return false
      }

      return NewsLetterSub.isValidOrFresh(sub.value)
    })

    async function sendNewsletterSub() {
      termsAndConditionsFirstTime.value = false

      if (!NewsLetterSub.isValid(sub.value)) {
        return
      }

      if (!termsAndConditions.value) {
        return
      }

      LoadingEventBus.show()
      try {
        await NewsletterSubAPIRepository.sub(sub.value)
      } catch (e) {

      }
      LoadingEventBus.hide()
    }

    return {
      sub,

      countries,
      provincesView,

      selectedCountryLabel,
      selectCountry,

      selectedProvinceLabel,
      selectProvince,

      termsAndConditions,

      isNameValid,
      isEmailValid,
      isLocationValid,
      isTermAndConditionsValid,
      isFormValid,

      sendNewsletterSub,
    }
  }
})

</script>

<style lang="scss" scoped>
.newsletter-sub {
  @apply flex;

  @apply border-1;
  @apply border-spl-gray-2;

  @apply rounded-xl;
  @apply overflow-hidden;

  &__link {
    @apply text-spl-primary-dark;
    @apply font-semibold;
  }

  &__link:hover {
    @apply text-spl-primary;
  }

  &__image {
    @apply hidden;

    @screen md {
      @apply block;
      @apply w-200p;
    }

    @screen lg {
      @apply w-300p;
    }

    background-image: url('~/assets/images/slider/Slide2.webp');
    background-size: cover;
  }

  &__form {
    @apply flex-1;

    @apply p-4;

    @screen md {
      @apply p-6;
    }

    &__error {
      @apply text-red-900;
    }

    &__title {
      @apply text-2xl font-bold;
      @apply text-spl-primary;
    }

    &__label {
      @apply p-2;

      @apply text-spl-primary-dark;
    }

    &__header-label {
      @apply p-2;
      @apply pt-4;

      @apply font-bold;

      @apply text-spl-primary-dark;
    }

    &__input-wrapper {
      @apply py-2;
      &__input {
        @apply border-1;
      }
    }

    &__button {
      @apply my-2;
    }

    &__location-seletor {
      @apply mt-2;
    }
  }
}
</style>

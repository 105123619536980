import { isValidEmailString, isValidSimpleText } from "../util/string"

enum ValidationStatus {
  FRESH = 0,
  VALID,
  INVALID
}

export type NewsLetterSubDTO = {
  name: string,
  email: string,
  political_location_uuid: string,
  lang_code: string,
}

export class NewsLetterSub {
  public name: string
  public email: string
  public politicalLocationUUID: string
  public langCode: string
  public nameValidationStatus: ValidationStatus = ValidationStatus.FRESH
  public emailValidationStatus: ValidationStatus = ValidationStatus.FRESH
  public locationValidationStatus: ValidationStatus = ValidationStatus.FRESH

  constructor(
    name: string,
    email: string,
    politicalLocationUUID: string,
    langCode: string,
  ) {
    this.name = name
    this.email = email
    this.politicalLocationUUID = politicalLocationUUID
    this.langCode = langCode

    this.nameValidationStatus = ValidationStatus.FRESH
    this.emailValidationStatus = ValidationStatus.FRESH
    this.locationValidationStatus = ValidationStatus.FRESH
  }

  public static createEmpty(langCode: string): NewsLetterSub {
    return new NewsLetterSub(
      '',
      '',
      '',
      langCode,
    )
  }

  public static isNameValidOrFresh(self: NewsLetterSub): boolean {
    if (self.nameValidationStatus === ValidationStatus.FRESH) {
      return true
    }

    return isValidSimpleText(self.name)
  }

  public static isEmailValidOrFresh(self: NewsLetterSub): boolean {
    if (self.emailValidationStatus === ValidationStatus.FRESH) {
      return true
    }

    return isValidEmailString(self.email)
  }

  public static isLocationValidOrFresh(self: NewsLetterSub): boolean {
    if (self.locationValidationStatus === ValidationStatus.FRESH) {
      return true
    }

    return isValidSimpleText(self.politicalLocationUUID)
  }

  public static isValid(self: NewsLetterSub): boolean {
    self.nameValidationStatus = ValidationStatus.VALID
    self.emailValidationStatus = ValidationStatus.VALID
    self.locationValidationStatus = ValidationStatus.VALID

    if (!isValidSimpleText(self.name)) {
      self.nameValidationStatus = ValidationStatus.INVALID
      return false
    }

    if (!isValidEmailString(self.email)) {
      self.emailValidationStatus = ValidationStatus.INVALID
      return false
    }

    if (!isValidSimpleText(self.politicalLocationUUID)) {
      self.locationValidationStatus = ValidationStatus.INVALID
      return false
    }

    return true
  }

  public static isValidOrFresh(self: NewsLetterSub): boolean {
    if (!this.isNameValidOrFresh(self)) {
      return false
    }

    if (!this.isEmailValidOrFresh(self)) {
      return false
    }

    if (!this.isLocationValidOrFresh(self)) {
      return false
    }

    return true
  }

  public static to(self: NewsLetterSub): NewsLetterSubDTO {
    return {
      name: self.name,
      email: self.email,
      political_location_uuid: self.politicalLocationUUID,
      lang_code: self.langCode,
    }
  }
}
